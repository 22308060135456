import { useEffect, useState } from 'react';
import { GoogleStars } from '@belong/ui';
import { useTransition, animated } from '@react-spring/web';
import { TrustpilotStars } from './components/trustpilot-stars/trustpilot-stars';

export function RatingRotator() {
  const [isFirstComponent, setIsFirstComponent] = useState(true);

  const transitions = useTransition(isFirstComponent, {
    from: {
      opacity: 0,
      transform: 'translateY(100%)',
    },
    enter: {
      opacity: 1,
      transform: 'translateY(0%)',
    },
    leave: {
      opacity: 0,
      transform: 'translateY(-100%)',
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFirstComponent((prev) => !prev);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative h-xl w-full overflow-hidden">
      {transitions((style, show) => (
        <animated.div style={style} className="absolute inset-0 flex items-center justify-center">
          {show ? <GoogleStars theme="dark" /> : <TrustpilotStars theme="dark" />}
        </animated.div>
      ))}
    </div>
  );
}
