// TODO: ASK IF THIS IS WHAT WE WANT
function chunk(array, size) {
  const cache = [];
  const tmp = array.slice(0);
  if (size <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, size));
  return cache;
}

export { chunk };
